<template>
    <div :class="CSSClasses" class="image-heading-block">
        <figure class="image-heading-figure" :style="figureStyles">
            <Image v-if="type !== 'noImage'" v-bind="image" class="image" />
            <img v-if="type === 'noImage'" src="~/assets/svg/topographic.svg?url" class="background" />

            <figcaption v-if="header?.headerTitle || isDekNotEmpty(image.caption)" class="image-heading-info">
                <Typography
                    class="title"
                    v-if="header?.headerTitle"
                    :as="header?.type"
                    :variant="`${header?.type}-display`"
                >
                    {{ header?.headerTitle }}
                </Typography>
                <RichTextElements v-if="isDekNotEmpty(subheader)" :elements="subheader" />
                <div v-if="buttons" class="image-heading-buttons">
                    <template v-for="button in buttons" :key="button.button?.label">
                        <CarnegieButton v-bind="{ ...button.button }" theme="dark" />
                    </template>
                </div>
            </figcaption>
        </figure>
    </div>
</template>

<script setup>
const props = defineProps({
    header: {
        type: Object,
    },
    subheader: {
        type: Object,
    },
    buttons: {
        type: Array,
    },
    image: {
        type: Object,
    },
    type: {
        type: String,
    },
    accentColor: {
        type: String,
    },
});
const themeColor = inject('tierTheme', 'light');
const CSSClasses = computed(() => [`theme-${themeColor}`, `type-${props.type}`]);

const figureStyles =
    props.type === 'noImage' ? { backgroundColor: props.accentColor ? props.accentColor : '#002744' } : {};
</script>

<style lang="scss" scoped>
.image-heading-block {
    position: relative;
    .image-heading-buttons {
        margin-top: 3rem;
        display: flex;
        flex-wrap: wrap;
        gap: 1.6rem;
    }
    .image-heading-figure {
        width: 100%;
        aspect-ratio: 393/844;
        position: relative;
        max-height: var(--image-max-height);
        @include media-query(phone-mw) {
            aspect-ratio: 774/994;
        }
        @include media-query(tablet-mw) {
            aspect-ratio: 2.25/1;
        }
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.08) 43.13%),
                linear-gradient(29deg, rgba(0, 0, 0, 0.8) 15.47%, rgba(0, 0, 0, 0) 55.9%);
        }

        @include media-query(phone) {
            &::after {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.08) 43.13%),
                    linear-gradient(29deg, rgba(0, 0, 0, 0.98) 25.47%, rgba(0, 0, 0, 0) 55.9%);
            }
        }
        .image {
            height: 100%;
            width: 100%;
        }
    }
    .image-heading-info {
        position: absolute;
        inset-inline-start: 0;
        bottom: 0;
        padding: 3.6rem 2.4rem;
        color: color(blanc);
        max-width: 65rem;
        z-index: 1;
        @include media-query(phone-mw) {
            padding-inline-start: 3.3rem;
            padding-inline-end: 3.3rem;
        }
        @include media-query(tablet-mw) {
            padding-inline-start: 8.4rem;
            padding-inline-end: 0;
        }
    }
    &.type-blueImage {
        .image-heading-figure {
            &::after {
                background: linear-gradient(180deg, rgba(0, 39, 68, 0.8) 0%, rgba(0, 0, 0, 0.08) 43.13%),
                    linear-gradient(29deg, rgba(0, 39, 68, 0.8) 15.47%, rgba(0, 0, 0, 0) 55.9%);
            }
        }
    }
    &.type-noImage {
        .image-heading-info {
            position: relative;
            padding-top: 8rem;
            padding-bottom: 8rem;
        }
        .image-heading-figure {
            aspect-ratio: unset;
            &::after {
                display: none;
            }
        }
        .background {
            position: absolute;
            top: 0;
            inset-inline-end: 0;
            height: 100%;
        }
    }
}
</style>
